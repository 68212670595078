<template>
    <div class="home-3">
        <Header />
        <Slider />
        <CreateAndSell />
        <LiveAution />
        <TopSeller />
        <TodayPick />    
        <Collection />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import Slider from '../components/Home03/Slider'
import LiveAution from '../components/Home03/LiveAution'
import CreateAndSell from '../components/Home03/CreateAndSell'
import Collection from '../components/Home03/Collection'
import TodayPick from '../components/Home03/TodayPick'
import TopSeller from '../components/Home03/TopSeller'
import Footer from '../components/Footer/Footer'


export default {
    name: 'Home03Page',
    components: {
        Header,
        Slider,
        LiveAution,
        CreateAndSell,
        Collection,
        TodayPick,
        TopSeller,
        Footer,
    }
}
</script>