<template>
    <section class="flat-title-page inner">
        <div class="overlay"></div>
        <div class="themesflat-container">
            <div class="row">
                <div class="col-md-12">
                    <div class="page-title-heading mg-bt-12">
                        <h1 class="heading text-center">{{pageTitle}}</h1>
                    </div>
                    <div class="breadcrumbs style2">
                        <ul>
                            <li> <router-link :to="'/'">Home
                                </router-link></li>
                            <li>
                               <router-link :to='link'>{{pageTitleActive}}
                                </router-link></li>
                            <li>{{pageTitle}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>                    
    </section>

</template>

<script>

export default {
    name: 'PageTitle',
    props: ['pageTitle','pageTitleActive','link']

}
</script>