<template>              
    <footer id="footer" class="footer-light-style clearfix bg-style">
        <div class="themesflat-container" v-if="footer !== null">
            <div class="row">
                <div class="col-lg-3 col-md-12 col-12">
                    <div class="widget widget-logo">
                        <div class="logo-footer" id="logo-footer">
                            <router-link to="/">
                                <img :src="footer.image.data.attributes.url" alt="image" id="logo-footer-img">
                            </router-link>
                        </div>
                        <p class="sub-widget-logo">{{footer.desc}}</p>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-5 col-5">
                    <div class="widget widget-menu style-1">
                        <h5 class="title-widget">{{footer.titleOne}}</h5>
                        <ul class="quick-links">
                            <li
                                v-for="link in footer.links"
                                :key="link.id"
                            >
                                <router-link :to="link.link">
                                    {{link.text}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-7 col-7">
                    <div class="widget widget-menu style-2">
                        <h5 class="title-widget">{{footer.titleTwo}}</h5>
                        <ul class="quick-links">
                            <li
                                v-for="link in footer.pages"
                                :key="link.id"
                            >
                                <router-link :to="link.link">
                                    {{link.text}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-5 col-5">
                    <div class="widget widget-menu fl-st-3">
                        <h5 class="title-widget">{{footer.titleThree}}</h5>
                        <ul class="quick-links">
                            <li
                                v-for="link in footer.links2"
                                :key="link.id"
                            >
                                <router-link :to="link.link">
                                    {{link.text}}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-7 col-12">
                    <div class="widget widget-subcribe">
                        <h5 class="title-widget">{{footer.titleFour}}</h5>
                        <div class="form-subcribe">
                            <form id="subscribe-form" action="#" method="GET" acceptCharset="utf-8" class="form-submit">
                                <input name="email"  class="email" type="email" placeholder="info@yourgmail.com" required />
                                <button id="submit" name="submit" type="submit"><i class="icon-fl-send"></i></button>
                            </form>
                        </div>
                        <div class="widget-social style-1 mg-t32">
                            <ul class="widget-social">
                            <li
                                v-for="social in footer.socials"
                                :key="social.id"
                            >
                                <a :href="social.link" target="_blank">
                                    <i :class="social.icon"></i>
                                </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true"  v-bind="{ show: isActive }">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-body space-y-20 pd-40" :class="{ show: isActive }">
                            <h3>Place a Bid</h3>
                            <p class="text-center">You must bid at least <span class="price color-popup">4.89 ETH</span>
                            </p>
                            <input type="text" class="form-control"
                                placeholder="00.00 ETH" />
                            <p>Enter quantity. <span class="color-popup">5 available</span>
                            </p>
                            <input type="number" class="form-control" placeholder="1" />
                            <div class="hr"></div>
                            <div class="d-flex justify-content-between">
                                <p> You must bid at least:</p>
                                <p class="text-right price color-popup"> 4.89 ETH </p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p> Service free:</p>
                                <p class="text-right price color-popup"> 0,89 ETH </p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p> Total bid amount:</p>
                                <p class="text-right price color-popup"> 4 ETH </p>
                            </div>
                            <router-link to="#" class="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</router-link>
                        </div>
                    </div>
                </div>
        </div>
    </footer>

    
</template>

<script>
import axios from 'axios';

export default {
    name: 'Footer',
    data() {
        return {
            footer: null
        };
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/footer?populate=*')
        const { data: {attributes} } = response.data
        this.footer = attributes
    },
}
</script>