<template>
    <section className="tf-section wrap-accordion">
        <div className="container" v-if="faqs !== null">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="tf-title-heading ct style-2 fs-30 mg-bt-10">
                        {{faqs.heading}}
                    </h2>
                    <h5 className="sub-title help-center mg-bt-32 ">
                        {{faqs.subtitle}}
                    </h5>
                </div>
                <div className="col-md-12">
                    <div class="flat-accordion2">
                        <accordion>
                            <accordion-item class="accordion-item"
                                v-for="faq in faqs.faqAccordion"
                                :key="faq.id"
                            >
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-button">
                                        {{faq.title}}
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>{{faq.desc}}</p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Accordion from "../Accordion/Accordion";
import AccordionItem from "../Accordion/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    },
    data (){
        return {
            faqs: null
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/faq?populate=*')
        const { data: {attributes} } = response.data
        this.faqs = attributes
    },
}
</script>