<template>
    <div >
        <Header />
        <PageTitle pageTitle="Help Center" pageTitleActive="Community"  link = "help-center" />
        <HelpCenter />
        
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import HelpCenter from '../components/HelpCenter.vue'

import Footer from '../components/Footer/Footer'


export default {
    name: 'HelpCenterPage',
    components: {
        Header,
        PageTitle,
        HelpCenter,
        Footer,
    }
}
</script>