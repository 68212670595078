<template>
    <div>
        <Header />
        <PageTitle pageTitle="Explore 02" pageTitleActive="Explore"  link = "explore-02" />
        <Filter />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'

import Filter from '../components/Layouts/Filter'
import Footer from '../components/Footer/Footer'


export default {
    name: 'Explore02',
    components: {
        Header,
        PageTitle,
        // LiveAution,
        Filter,
        Footer,
    }
}
</script>