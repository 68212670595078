<template>
    <div>
        <Header />
        <PageTitle pageTitle="FAQ" pageTitleActive="Pages"  link = "faq" />
        <Faq />
        <Footer />
    </div>
</template>

<script>

import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import Faq from '../components/Faq/Faq'
import Footer from '../components/Footer/Footer'

export default {
    name: 'FaqPage',
    components: {
        Header,
        PageTitle,
        Faq,
        Footer,
    }
}
</script>