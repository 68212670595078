<template>
    <li class="accordion-item">
        <div 
            class="accordion-title"
            :class="{'active': visible}"
            @click="show"
        >
            <slot name="accordion-trigger"></slot>
        </div>

        <transition 
            name="accordion"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
        >
            <div 
                class="accordion-content"
                v-show="visible"
            >
                <ul>
                    <slot name="accordion-content"></slot>
                </ul>
            </div>
        </transition>
    </li>
</template>

<script>
export default {
    name: 'AccordionItem',
    props: {},
    inject: ["Accordion"],
    data() {
        return {
            index: null
        };
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active;
        }
    },
    methods: {
        show() {
            if (this.visible) {
                this.Accordion.active = null;
            } else {
                this.Accordion.active = this.index;
            }
        },
        startTransition(el) {
            el.style.height = el.scrollHeight + "px";
        },
        endTransition(el) {
            el.style.height = "";
        }
    },
    created() {
        this.index = this.Accordion.count++;
    }
}
</script>