<template>
    <div class="item-details">
        <Header />
        <PageTitle pageTitle="Activity" pageTitleActive="Activity"  link = "activity-01" />
        <section class="tf-activity tf-section" v-if="activity != null">
            <div class="themesflat-container">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-12" >
                        <div class="box-activity">
                            <div class="sc-card-activity style-2"  v-for="activity in filtered.slice(0,8)" 
                            :key="activity.id">
                                <div class="content">
                                    <div class="media">
                                        <img :src="activity.image.data.attributes.url" alt="image" class="avatar">
                                    </div>
                                    <div class="infor">
                                        <h4><router-link to="/item-details-01">{{activity.title}}</router-link></h4>
                                        <div class="status">{{activity.activity}} by <span class="author">{{activity.nameAuthor}}</span></div>
                                    <div class="time">{{activity.Desc}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 wrap-inner load-more text-center"> 
                            <a id="load-more" class="sc-button loadmore fl-button pri-3 mt-6"  @click="handleButton">
                                <span>Load More</span>
                            </a>
                            
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">

                        <div id="side-bar" class="side-bar style-2">

                            <div class="widget widget-search mgbt-24">
                                <form action="#">
                                    <input type="text" placeholder="Enter your word art" required />
                                    <button><i class="icon-fl-search-filled"></i></button>
                                </form>
                            </div>

                            <div class="widget widget-filter style-1 mgbt-0">
                               <div class="header-widget-filter">
                                    <h3 class="title-widget">Filter</h3>
                                    <router-link to="#" class="clear-check btn-filter style-2" v-on:click="selectedCategory ='All'">
                                        Clear All
                                    </router-link>
                               </div>

                                <form class="filter box-check form-inner">
                                    <div v-for="item in unique" :key="item.id" v-on:click="selectedCategory = item.activity"  >
                                      
                                        <label >
                                            {{item.activity}}
                                            <input type="checkbox" :id="`${selectedCategory == item.activity ? 'checked' : 'no-checked'}`">
                                             
                                            <span  class="btn-checkbox" :class="{active:selectedCategory == item.activity}" ></span>
                                        </label>
                                    </div>
                                </form>           
                                
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'

import Footer from '../components/Footer/Footer'
import axios from 'axios'


// document.getElementById("checked").checked = true;
// document.getElementById("no-checked").checked = false;

export default {
    name: 'Activity02',
    components: {
        Header,
        PageTitle,
        Footer,
    },
    data (){
        return{
            activity: [],
            selectedCategory: "All",
            activity1: [],
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/activity-01?populate=Activity.image')
        const { data: {attributes} } = response.data
        this.activity = attributes
        // this.activity1 = this.activity.splice(0, 6);

    },
    computed: {
        filtered: function() {
			var activity = this.selectedCategory;
			
			if(activity === "All") {
				return this.activity.Activity;
			} else {
				return this.activity.Activity.filter(function(item) {
					return item.activity === activity;
				});
			} 
		},
        unique: function(){
            var arr = this.activity.Activity;
            var newArr = []
            newArr = arr.filter(function (item) {
                return newArr.includes(item.activity) ? '' : newArr.push(item.activity)
            })
            return newArr
        }
        
    },
    methods: {
        
        // handleButton: function () { 
        //     if (this.activity.length < this.activity1.length) {
        //         var nextEsts = this.activity.splice(0, 3);
        //         this.activity1 = this.activity1.concat(nextEsts);
        //     }
        //     // else if (this.blogs.length >= this.activity1.length) {
        //        document.getElementById("load-more").classList.add("none")
        //     // }
            
        // },
    }
    
}



</script>