<template>
    <div >
        <Header />
        <PageTitle pageTitle="Connect Wallet" pageTitleActive="Connect Wallet"  link = "wallet-connect" />
        <WalletConnect />
        
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import WalletConnect from '../components/WalletConect.vue'

import Footer from '../components/Footer/Footer'


export default {
    name: 'WalletConnectPage',
    components: {
        Header,
        PageTitle,
        WalletConnect,
        Footer,
    }
}
</script>