<template>
    <section class="tf-section authors">
        <div class="themesflat-container">
            <div class="flat-tabs tab-authors">
                <div class="author-profile flex">
                    <div class="feature-profile">
                        <img :src="details[0].attributes.image.data.attributes.url" alt="image" class="avatar">
                    </div>
                    <div class="infor-profile">
                        <span>{{details[0].attributes.heading}}</span>
                        <h2 class="title">{{details[0].attributes.title}}</h2>
                        <p class="content">{{details[0].attributes.des}}</p>
                        <form>
                            <input type="text" class="inputcopy" defaultValue="DdzFFzCqrhshMSxABCdfrge" readOnly />
                            <button type="button" class="btn-copycode"><i class="icon-fl-file-1"></i></button>
                        </form>
                    </div>
                    <div class="widget-social style-3">
                        
                        <ul>
                            <li
                                v-for="social in details[0].attributes.social"
                                :key="social.id"
                            >
                                <a :href="social.link" target="_blank">
                                    <i :class="social.icon"></i>
                                </a>
                            </li>
                        </ul>
                        <div class="btn-profile"><router-link :to="details[0].attributes.btnLink" class="sc-button style-1 follow">{{details[0].attributes.btnText}}</router-link></div>
                    </div>
                </div>
                <Filter />
            </div>
        </div>
    </section>

</template>

<script>
import axios from 'axios'
import Filter from './Filter.vue'

export default {
    name: 'AuthorDetailPage',
    props: ['detailsContent'],
    components: {
        Filter,
    },
    data: function () {
        return {
            blogs: [],
            details: this.detailsContent
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/authors?populate=*', {params: {_limit: 4}})
        this.blogs = response.data.data
    },
}
</script>