<template>
    <div>
        <Header />
        <PageTitle pageTitle="Blog" pageTitleActive="Community"  link = "blog" />
        <Blog />
        <Footer />
    </div>
</template>

<script>

import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import Blog from '../components/Blog/Blog'
import Footer from '../components/Footer/Footer'

export default {
    name: 'BlogPage',
    components: {
        Header,
        PageTitle,
        Blog,
        Footer,
    }
}
</script>