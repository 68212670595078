<template>
    <div className="mode_switcher">
        <h6>Dark mode <strong>Available</strong></h6>
        <a href="#"  @click="toggleTheme"><img src='../../assets/images/item-background/sun.png' alt="Image" /></a>
    </div>
</template>

<script>


export default {
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  data() {
    return {
      userTheme: "is_dark",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "is_dark") {
        this.setTheme("light");
        document.getElementById("logo-all").src=require(`../../assets/images/item-background/logo@2x.png`);
        document.getElementById("logo-footer-img").src=require(`../../assets/images/item-background/logo2@2x.png`);
      } else {
        this.setTheme("is_dark");
        document.getElementById("logo-all").src=require(`../../assets/images/item-background/logo_dark@2x.png`);
        document.getElementById("logo-footer-img").src=require(`../../assets/images/item-background/logo_dark@2x.png`);
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "is_dark";
      } else {
        return "is_light";
      }
    },
  },
};
</script>
