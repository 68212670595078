<template>
    <div>
        <div :class="['header_1', { 'is-fixed': isSticky }]" id="header_main">
            
            <div class="themesflat-container">
                <div class="row">
                    <div class="col-md-12">
                         <div id="site-header-inner">
                            <nav 
                                class="wrap-box flex"
                                v-if="Header !== null"
                            >
                                <div id="site-logo" class="clearfix">
                                    <div id="site-logo-inner">
                                    <router-link class="main-logo" to="/">
                                        <img :src="HeaderImg" alt="image" id="logo-all">
                                    </router-link>
                                    </div>
                                </div>
                                <div class="mobile-button" @click="toggleActive()" v-bind:class="{active: isActive}" ><span></span></div>
                                <div id="main-nav" class="main-nav" v-bind:class="{active: isActive}">
                                    <ul id="menu-primary-menu" class="menu">
                                        <li class="menu-item  menu-item-has-children" @click="toggleActiveMobile()" v-bind:class="{active: isActiveMobile}">
                                            <a href="#" >
                                                Home 
                                            </a>

                                            <ul class="sub-menu">
                                                <li class="menu-item " >
                                                    <router-link to="/" >
                                                        Home 01
                                                    </router-link>
                                                </li>
                                                <li class="menu-item  ">
                                                    <router-link to="/home-02" >
                                                        Home 02
                                                    </router-link>
                                                </li>
                                                <li class="menu-item  ">
                                                    <router-link to="/home-03" >
                                                        Home 03
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="menu-item  menu-item-has-children" @click="toggleActiveMobile1()" v-bind:class="{active: isActiveMobile1}">
                                            <a href="#" >
                                                Explore 
                                                
                                            </a>

                                            <ul class="sub-menu">
                                                <li class="menu-item  ">
                                                    <router-link to="/explore-01" >
                                                        Explore 01
                                                    </router-link>
                                                </li>
                                                <li class="menu-item  ">
                                                    <router-link to="/explore-02" >
                                                        Explore 02
                                                    </router-link>
                                                </li>
                                                <li class="menu-item  ">
                                                    <router-link to="/live-autions" >
                                                        LiveAuctions
                                                    </router-link>
                                                </li>
                                                <li class="menu-item  ">
                                                    <router-link to="/item-details-01" >
                                                        ItemDetails 01
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="menu-item  menu-item-has-children" @click="toggleActiveMobile2()" v-bind:class="{active: isActiveMobile2}">
                                            <a href="#" >
                                                Activity 
                                                
                                            </a>

                                            <ul class="sub-menu">
                                                <li class="menu-item ">
                                                    <router-link to="/activity-01" >
                                                        Activity 01
                                                    </router-link>
                                                </li>
                                                <li class="menu-item ">
                                                    <router-link to="/activity-02" >
                                                        Activity 02
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="menu-item  menu-item-has-children" @click="toggleActiveMobile3()" v-bind:class="{active: isActiveMobile3}">
                                            <a href="#"  >
                                                Community 
                                                
                                            </a>

                                            <ul class="sub-menu">
                                                <li class="menu-item ">
                                                    <router-link to="/blog" >
                                                        Blog
                                                    </router-link>
                                                </li>
                                                <li class="menu-item ">
                                                    <router-link to="/single/the-next-big-trend-in-crypto" >
                                                        Blog Details
                                                    </router-link>
                                                </li>
                                                <li class="menu-item ">
                                                    <router-link to="/help-center" >
                                                        Help Center
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="menu-item  menu-item-has-children" @click="toggleActiveMobile4()" v-bind:class="{active: isActiveMobile4}">
                                            <a href="#" >
                                                Page 
                                                
                                            </a>
                                            <ul class="sub-menu">
                                            
                                                <li class="menu-item ">
                                                    <router-link to="/author/trista-francis" >
                                                        Authors
                                                    </router-link>
                                                </li>
                                                <li class="menu-item ">
                                                    <router-link to="/wallet-connect" >
                                                        Wallet Connect
                                                    </router-link>
                                                </li>

                                                <li class="menu-item ">
                                                    <router-link to="/faq" >
                                                       FAQ
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>


                                        <li class="menu-item  menu-item-has-children" @click="toggleActiveMobile5()" v-bind:class="{active: isActiveMobile5}">
                                            <a href="#" >
                                                Contact 
                                                
                                            </a>
                                            <ul class="sub-menu">
                                                <li class="menu-item ">
                                                    <router-link to="/contact-01" >
                                                        Contact 01
                                                    </router-link>
                                                </li>
                                                <li class="menu-item ">
                                                    <router-link to="/contact-02" >
                                                        Contact 02
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>
                                <div class="flat-search-btn flex">
                                <div class="header-search flat-show-search" id="s1">
                                    <router-link to="#" class="show-search header-search-trigger" @click="toggleActiveSearch()" >
                                        <i class="far fa-search"></i>
                                    </router-link>
                                    <div class="top-search" :class="{ active: search }">
                                        <form action="#" method="get" role="search" class="search-form">
                                            <input type="search" id="s" class="search-field" placeholder="Search..." name="s" title="Search for" required="" />
                                            <button class="search search-submit" type="submit" title="Search">
                                                <i class="icon-fl-search-filled"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div class="sc-btn-top mg-r-12" id="site-header">
                                    <router-link to="/wallet-connect" class="sc-button header-slider style style-1 wallet fl-button pri-1"><span>Wallet connect
                                    </span></router-link>
                                </div>

                                <div class="admin_active" id="header_admin">
                                    <div class="header_avatar">
                                        <div class="price">
                                            <span>2.45 <strong>ETH</strong> </span>
                                        </div>
                                        <img
                                            class="avatar"
                                            src={avt}
                                            alt="avatar"
                                            />
                                        <div class="avatar_popup mt-20">
                                            <div class="d-flex align-items-center copy-text justify-content-between">
                                                <span> 13b9ebda035r178... </span>
                                                <router-link to="/" class="ml-2">
                                                    <i class="fal fa-copy"></i>
                                                </router-link>
                                            </div>
                                            <div class="d-flex align-items-center mt-10">
                                                <img
                                                    class="coin"
                                                    src={imgsun}
                                                    alt="/"
                                                    />
                                                <div class="info ml-10">
                                                    <p class="text-sm font-book text-gray-400">Balance</p>
                                                    <p class="w-full text-sm font-bold text-green-500">16.58 ETH</p>
                                                </div>
                                            </div>
                                            <div class="hr"></div>
                                            <div class="links mt-20">
                                                <router-link to="#">
                                                    <i class="fab fa-accusoft"></i> <span> My items</span>
                                                </router-link>
                                                <a class="mt-10" href="/edit-profile">
                                                    <i class="fas fa-pencil-alt"></i> <span> Edit Profile</span>
                                                </a>
                                                <a class="mt-10" href="/login" id="logout">
                                                    <i class="fal fa-sign-out"></i> <span> Logout</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                            </nav>
                            
                         </div>
                    </div>

                </div>
            </div>
             <DarkMode />
        </div>
         
    </div>
</template>

<script>
import axios from 'axios'

import DarkMode from '../Layouts/DarkMode.vue';

export default {
    name: "Header",
    components: {
        DarkMode
    },
    data() {
        return {
            isSticky: false,
            isActive: false,
            isActiveMobile:false,
            isActiveMobile1:false,
            isActiveMobile2:false,
            isActiveMobile3:false,
            isActiveMobile4:false,
            isActiveMobile5:false,
            search: false,
            Header: null,
            HeaderImg: null
        };
    },
    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    methods: {
        isSideMenuMethod(isSideMenu){
            return this.isSideMenu = !isSideMenu
        },
        toggleActive(){
            this.isActive = !this.isActive;
        },
        toggleActiveMobile(){
            this.isActiveMobile = !this.isActiveMobile;
        },
        toggleActiveMobile1(){
            this.isActiveMobile1 = !this.isActiveMobile1;
        },
        toggleActiveMobile2(){
            this.isActiveMobile2 = !this.isActiveMobile2;
        },
        toggleActiveMobile3(){
            this.isActiveMobile3 = !this.isActiveMobile3;
        },
        toggleActiveMobile4(){
            this.isActiveMobile4 = !this.isActiveMobile4;
        },
        toggleActiveMobile5(){
            this.isActiveMobile5 = !this.isActiveMobile5;
        },
         toggleActiveSearch(){
            this.search = !this.search;
        },
    },

    
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/header?populate=*')
        const { data: {attributes} } = response.data
        const {image: {data: { attributes: {url} }}} = attributes
        this.Header = attributes
        this.HeaderImg = url
    },
};
</script>
