<template>
    <section class="tf-section top-seller">
        <div class="themesflat-container" v-if="blogs !== []">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-live-auctions">
                        <h2 class="tf-title">
                            Top Seller</h2>
                    </div>
                </div>
                <div class="col-md-12">
                    <carousel
                        :autoplay="false"
                        :settings='settings' 
                        :wrap-around="true"
                        :breakpoints='breakpoints' >
                            <Slide v-for="blog in blogs.slice(0,10)" :key="blog.id">
                                <div class="sc-author-box style-2">
                                    <div class="author-avatar">
                                            <img :src="blog.attributes.image.data.attributes.url" alt="image" class="avatar">
                                        <div class="badge"></div>
                                    </div>
                                    <div class="author-infor">
                                        <h5><router-link :to="'/author/' + blog.attributes.slug">
                                                            {{blog.attributes.title}}
                                                        </router-link></h5>
                                        <span class="price">{{blog.attributes.price}}</span>
                                    </div>
                                </div>
                            </Slide>

                        <template #addons>
                            <Navigation />

                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';
import { Carousel, Slide ,Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'TopSeller',
    components: {
        Carousel,
        Slide,
        Navigation,
        
    },
    data: () => ({
        settings: {
            loop: false,
            itemsToShow: 1,
            snapAlign: 'left',
        },
        blogs: [],
        breakpoints: {
            0: {
                itemsToShow: 3,
                snapAlign: 'left',
			},
            768: {
                itemsToShow: 5,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 9,
                snapAlign: 'left',
            },
        },
    }),
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/authors?populate=*')
        this.blogs = response.data.data
    },
})
</script>