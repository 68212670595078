import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import Toaster from "@meforma/vue-toaster";
import AOS from "aos";
import "aos/dist/aos.css";

import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/shortcodes.css";
import "./assets/css/responsive.css";

const app = createApp(App).use(router).use(Toaster);
app.use(VueAxios, axios);
app.use(AOS.init).mount("#app");

