<template>
    <div >
        <Header />
        <PageTitle pageTitle="Explore 01" pageTitleActive="Explore"  link = "explore-01" />
        <LiveAution />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import LiveAution from '../components/Explore01/LiveAution'
import Footer from '../components/Footer/Footer'


export default {
    name: 'Explore01',
    components: {
        Header,
        PageTitle,
        LiveAution,
        Footer,
    }
}
</script>