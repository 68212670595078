<template>
  <div class="tab-content">
    <ul class="bid-history-list " v-if="bid !== null">
      <li v-for="create in bid.Bid"
                        :key="create.id">
          <div class="content">
              <div class="client">
                  <div class="sc-author-box style-2">
                      <div class="author-avatar">
                          <router-link to="#">
                              <img :src="create.imgAuthor.data.attributes.url" alt="image">
                          </router-link>
                          <div class="badge"></div>
                      </div>
                      <div class="author-infor">
                          <div class="name">
                              <h6><router-link to="/author/trista-francis">{{create.nameAuthor}}</router-link></h6> <span> place a bid</span>
                          </div>
                          <span class="time">{{create.date}}</span>
                      </div>
                  </div>
              </div>
              <div class="price">
                  <h5>{{create.price}}</h5>
                  <span>= {{create.priceChange}}</span>
              </div>
          </div>
      </li>
    </ul>
  </div>
</template>


<script>
import axios from 'axios'

export default {
    name: 'Bid',
    data (){
        return {
            bid: null
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/tab?populate=Bid.imgAuthor')
        const { data: {attributes} } = response.data
        this.bid = attributes
    },
}
</script>