<template>

    <div class="blog-area pt-100 pb-100">

        <div class="tf-section sc-card-blog dark-style2">
            <div class="themesflat-container">
                  
            <div 
                class="row"
                v-if="blogs !== []"
            >
                <div 
                    class="fl-blog fl-item2 col-lg-4 col-md-6"
                    v-for="blog in moreBlogs"
                    :key="blog.id"
                >
                    <div 
                        class="sc-card-article" 
                        data-aos="fade-up" 
                        data-aos-delay="80" 
                        data-aos-duration="800" 
                        data-aos-once="true"
                    >


                        <div class="card-media">
                            <router-link :to="'/single/' + blog.attributes.slug">
                                <img :src="blog.attributes.image.data.attributes.url" alt="image">
                            </router-link>
                        </div>


                        <div class="meta-info">
                             <div class="author">
                                <div class="avatar">
                                    <img :src="blog.attributes.imgauthor.data.attributes.url" alt="image">
                                </div>
                                <div class="info">
                                    <span>Post owner</span>
                                    <h6>  <router-link :to="'/single/' + blog.attributes.slug">{{blog.attributes.authorname}} </router-link> </h6>
                                </div>
                            </div>
                            <div class="date">{{blog.attributes.date}}</div>
                        </div>
                            
                            
                            <div class="text-article">
                                <h3>
                                    <router-link :to="'/single/' + blog.attributes.slug">
                                        {{blog.attributes.title}}
                                    </router-link>
                                </h3>
                                <p>{{blog.attributes.desc}}</p>
                            </div>

                            <router-link 
                                :to="'/single/' + blog.attributes.slug" 
                                class="sc-button fl-button pri-3"
                            >
                               <span>{{blog.attributes.btnText}}</span> 
                            </router-link>

                    </div>
                </div>

                <div class="col-md-12 wrap-inner load-more text-center">
                    <a id="load-more" class="sc-button loadmore fl-button pri-3 mt-6"  @click="handleButton">
                        <span>Load More</span>
                    </a>
                </div>
            </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Blog',
    data (){
        return{
            blogs: [],
            moreBlogs: [],
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/blogs?populate=*', {params: {_limit: 4}})
        this.blogs = response.data.data
        this.moreBlogs = this.blogs.splice(0, 6);
    },
    methods: {
        
        handleButton: function () { 
            if (this.blogs.length < this.moreBlogs.length) {
                var nextEsts = this.blogs.splice(0, 3);
                this.moreBlogs = this.moreBlogs.concat(nextEsts);
            }
            // else if (this.blogs.length >= this.moreBlogs.length) {
               document.getElementById("load-more").classList.add("none")
            // }
            
        },
    }
}
</script>