<template>

    <section class="tf-connect-wallet tf-section " v-if="walletconnects !== null">
        <div class="themesflat-container">
            <div class="row">
                <div class="col-12">
                    <h2 class="tf-title-heading ct style-2 mg-bt-12">
                        {{walletconnects.heading}}
                    </h2>
                    <h5 class="sub-title ct style-1 pad-400">
                         {{walletconnects.subHeading}}
                    </h5>
                </div>
                <div class="col-md-12" >
                    <div class="sc-box-icon-inner style-2"  >
                        <div class="sc-box-icon" v-for="item in walletconnects.WalletConnect"
                        :key="item.id">
                            <div class="img">
                                <img :src="item.img.data.attributes.url" alt="image">
                            </div>
                            <h4 class="heading">{{item.title}}</h4>
                            <p class="content">{{item.des}}</p>
                        </div>
                    </div>  
                </div>    
            </div>              
        </div>
    </section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'WallectConnect',
    data (){
        return {
            walletconnects: null
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/wallet-connect?populate=WalletConnect.img')
        const { data: {attributes} } = response.data
        this.walletconnects = attributes
    },
}
</script>