<template>
    <div>
        <Header />
        <PageTitle pageTitle="Contact 1" pageTitleActive="Contact"  link = "contact-01" />
        <section class="tf-contact tf-section" v-if="contact !== []">
            <div class="themesflat-container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="box-feature-contact">
                            <img :src="contact.img.data.attributes.url" alt="image">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <h2 class="tf-title-heading style-2 mg-bt-12">
                            {{contact.heading}}
                        </h2>
                        <h5 class="sub-title style-1">
                            {{contact.subHeading}}
                        </h5>
                        <div class="form-inner">
                            <form id="contactform"  class="form-submit">
                                <input id="name" name="name" tabIndex="1" aria-required="true" type="text" placeholder="Your Full Name" required />
                                <input id="email" name="email" tabIndex="2"  aria-required="true" type="email" placeholder="Your Email Address" required />
                                <div class="row-form style-2" id="subject">
                                    <select>
                                        <option value="1">Select subject</option>
                                        <option value="2">Select subject</option>
                                        <option value="3">Select subject</option>
                                    </select>
                                    <i class="icon-fl-down"></i>
                                </div>
                                <textarea id="message" name="message" tabIndex="3" aria-required="true" required placeholder="Message"></textarea>
                                <button class="submit">Send message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'

import Footer from '../components/Footer/Footer'
import axios from 'axios';


export default {
    name: 'Contact01Page',
    components: {
        Header,
        PageTitle,
        Footer,
    },
    data (){
        return{
            contact: [],
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/contact-1?populate=*')
        const { data: {attributes} } = response.data
        this.contact = attributes
    },
}
</script>