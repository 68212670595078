<template>
    <div class="home-2">
        <Header />
        <Slider />
        <CreateAndSell />
        <LiveAution />
        <Collection />
        <TopSeller />
        <TodayPick />    
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import Slider from '../components/Home02/Slider'
import LiveAution from '../components/Home02/LiveAution'
import CreateAndSell from '../components/Home02/CreateAndSell'
import Collection from '../components/Home02/Collection'
import TodayPick from '../components/Home02/TodayPick'
import TopSeller from '../components/Home02/TopSeller'
import Footer from '../components/Footer/Footer'


export default {
    name: 'Home02Page',
    components: {
        Header,
        Slider,
        LiveAution,
        CreateAndSell,
        Collection,
        TodayPick,
        TopSeller,
        Footer,
    }
}
</script>