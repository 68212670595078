<template>
    <section class="tf-section live-auctions bg-style">
        <div class="themesflat-container" v-if="auction !== null">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading-live-auctions">
                        <h2 class="tf-title pb-20">
                            {{auction.heading}}</h2>
                        <router-link :to="auction.btnLink" class="exp style2">{{auction.btnText}}</router-link>
                    </div>
                </div>
                <div class="col-md-12">
                    <carousel
                        :autoplay="false"
                        :settings='settings' 
                        :breakpoints='breakpoints'
                        v-if="auction !== null"
                    >
                        <Slide 
                            v-for="slide in auction.Product.slice(0,6)" 
                            :key="slide.id"
                        >										  
                                <div class="sc-card-product explode style2">
                                    <div class="card-media">
                                         <router-link to="/item-details-01"> <img :src="slide.image.data.attributes.url" alt="image"></router-link>
                                        <div class="featured-countdown">
                                            <span class="slogan"></span>   
                                            <Countdown 
                                                starttime="Jul 1, 2022 15:37:25" 
                                                endtime="Aug 8, 2022 16:37:25" >
                                            </Countdown> 
                                        </div>
                                        <div class="button-place-bid">
                                           <button class="sc-button style-place-bid style bag fl-button pri-3"  v-on:click="toggleActive(isActive)"><span>{{slide.btnText}}</span></button>
                 
                                        </div>
                                    </div>
                                    <div class="card-title">
                                        <h5><router-link to="/item-details-01">{{slide.title}}</router-link></h5>
                                    </div>
                                    <div class="meta-info">
                                        <div class="author">
                                            <div class="avatar">
                                                <img :src="slide.imgAuthor.data.attributes.url" alt="image">
                                            </div>
                                            <div class="info">
                                                <span>Creator</span>
                                                <h6>  <router-link to="/author/trista-francis">{{slide.nameAuthor}}</router-link> </h6>
                                            </div>
                                        </div>
                                        <div class="tags">{{slide.tag}}</div>
                                    </div>
                                    <div class="card-bottom style-explode">
                                        <div class="price">
                                            <span>Current Bid</span>
                                            <div class="price-details">
                                                <h5>{{slide.price}}</h5>
                                                <span>= $12.24</span>
                                            </div>
                                        </div>
                                        <router-link to="/login" class="wishlist-button public heart"><span class="number-like">100</span></router-link>
                                    </div>
                                </div>									    		
                        </Slide>

                        <template #addons>
                            <Navigation  />
                            <Pagination  />
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
        <div class="modal fade popup" id="popup_bid" tabIndex="-1" role="dialog" aria-hidden="true"  :class="{ show: isActive }">
            <div class="overlay" v-on:click="toggleActive(isActive)"></div>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" v-on:click="toggleActive(isActive)">  
                    </button>
                    <div class="modal-body space-y-20 pd-40" :class="{ show: isActive }">
                        <h3>Place a Bid</h3>
                        <p class="text-center">You must bid at least <span class="price color-popup">4.89 ETH</span>
                        </p>
                        <input type="text" class="form-control"
                            placeholder="00.00 ETH" />
                        <p>Enter quantity. <span class="color-popup">5 available</span>
                        </p>
                        <input type="number" class="form-control" placeholder="1" />
                        <div class="hr"></div>
                        <div class="d-flex justify-content-between">
                            <p> You must bid at least:</p>
                            <p class="text-right price color-popup"> 4.89 ETH </p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p> Service free:</p>
                            <p class="text-right price color-popup"> 0,89 ETH </p>
                        </div>
                        <div class="d-flex justify-content-between">
                            <p> Total bid amount:</p>
                            <p class="text-right price color-popup"> 4 ETH </p>
                        </div>
                        <router-link to="/wallet-connect" class="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue';
import { Carousel, Slide ,Navigation,Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import Countdown from '../Layouts/Countdown.vue';


export default defineComponent ({
    name: 'LiveAution',
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination,
        Countdown,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            
        },
        auction: null,
        isActive: false,
        breakpoints: {
            768: {
                itemsToShow: 2,
            },
            991: {
                itemsToShow: 2,
            },
            1200: {
                itemsToShow: 4,
                
            },
        },
    }),
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/live-auction?populate=Product.image,Product.imgAuthor')
        const { data: {attributes} } = response.data
        this.auction = attributes
    },
    methods: {
        toggleActive(){
            this.isActive = !this.isActive;
        },
    }

})
</script>