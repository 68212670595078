<template>
    <div class="item-details">
        <Header />
        <PageTitle pageTitle="Item Details 1" pageTitleActive="Explore"  link = "explore-01" />
        <div class="tf-section tf-item-details">
            <div class="themesflat-container " v-if="itemdetail != null">
                <div class="row">
                    <div class="col-xl-6 col-md-12">
                        <div class="content-left">
                            <div class="media">
                                <img :src="itemdetail.img.data.attributes.url" alt="image">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-12">
                        <div class="content-right">
                            <div class="sc-item-details">
                                <h2 class="style2">{{itemdetail.title}} </h2>
                                <div class="meta-item">
                                    <div class="left">
                                        <span class="viewed eye">225</span>
                                        <span to="/login" class="liked heart wishlist-button mg-l-8"><span class="number-like">100</span></span>
                                    </div>
                                    <div class="right">
                                        <router-link to="#" class="share"></router-link>
                                        <router-link to="#" class="option"></router-link>
                                    </div>
                                </div>
                                <div class="client-infor sc-card-product">
                                    <div class="meta-info">
                                        <div class="author">
                                            <div class="avatar">
                                                <img :src="itemdetail.imgOwner.data.attributes.url" alt="image">
                                            </div>
                                            <div class="info">
                                                <span>Owned By</span>
                                                <h6> <router-link to="/author/trista-francis">{{itemdetail.nameOwner}}</router-link> </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="meta-info">
                                        <div class="author">
                                            <div class="avatar">
                                                <img :src="itemdetail.imgAuthor.data.attributes.url" alt="image">
                                            </div>
                                            <div class="info">
                                                <span>Create By</span>
                                                <h6> <router-link to="/author/trista-francis">{{itemdetail.nameAuthor}}</router-link> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>{{itemdetail.Desc}}</p>
                                <div class="meta-item-details style2">
                                    <div class="item meta-price">
                                        <span class="heading">Current Bid</span>
                                        <div class="price">
                                            <div class="price-box">
                                                <h5> 4.89 ETH</h5>
                                                <span>= $12.246</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item count-down">
                                        <span class="heading style-2">Countdown</span>
                                        <Countdown 
                                            starttime="Jul 1, 2022 15:37:25" 
                                            endtime="Aug 8, 2022 16:37:25" >
                                        </Countdown>
                                    </div>
                                </div>
                                <router-link to="/wallet-connect" class="sc-button loadmore style bag fl-button pri-3"><span>Place a bid</span></router-link>
                                <div class="flat-tabs themesflat-tabs">
                                <Tab />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LiveAution />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header/Header'
import PageTitle from '../components/PageTitle/PageTitle'
import LiveAution from '../components/Home01/LiveAution'
import Countdown from '../components/Layouts/Countdown';
import Tab from '../components/Tab/Tab';
import Footer from '../components/Footer/Footer'
import axios from 'axios'


export default {
    name: 'ItemDetail01Page',
    components: {
        Header,
        PageTitle,
        LiveAution,
        Footer,
        Countdown,
        Tab,
    },
    data (){
        return{
            itemdetail: null,
        }
    },
    created: async function (){
        const response = await axios.get('https://seahorse-app-7dtfg.ondigitalocean.app/api/item-detail?populate=*')
        const { data: {attributes} } = response.data
        this.itemdetail = attributes
    },
}
</script>