<template>
    <div class="tab">
        <div class="tab-menu">
            <span
            v-for="tab in tabs"
            :key="tab"
            :class="['tab-button', { active: currentTab === tab }]"
            @click="currentTab = tab"
            >
            {{ tab }}
            </span>
        </div>
	    <Component :is="currentTab" class="tab" />
    </div>
</template>

<script>


import Info from './Info.vue'
import Provenance from './Provenance.vue'
import BidHistory from './BidHistory.vue'
  
export default {
    components: {
        Info,
        Provenance,
        BidHistory
    },
    data() {
        return {
            currentTab: 'BidHistory',
            tabs: ['BidHistory', 'Info', 'Provenance']
        }
    }
}

</script>